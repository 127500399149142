import * as React from 'react';
import { useState } from 'react';

import { cn } from '@blockworks/ui/utils';

import IframePopup from '@/components/display/popup/iframe-popup';
import useAnalyticsTrigger from '@/utils/hooks/use-analytics-trigger';

type BuyTicketsButtonProps = {
    className?: string;
    buttonClassName?: string;
    url: string;
    target: string | '' | '_blank';
    title: string;
    featured?: boolean;
    skipFeatureStyling?: boolean;
    price?: string;
};

const BuyTicketsButton = (props: BuyTicketsButtonProps) => {
    const { className, buttonClassName, url, target, title, price, featured } = props;
    const [isOpen, setIsOpen] = useState(false);

    const trackButtonClick = useAnalyticsTrigger('Button Clicked', {
        type: 'Event Tickets Button',
        url: url || '',
    });

    return (
        <div className={cn('relative', className)}>
            <a
                href={target === '' ? undefined : url}
                target={target || '_blank'}
                onClick={() => {
                    trackButtonClick.trigger();
                    if (target === '') {
                        setIsOpen(true);
                    }
                }}
                className={cn(
                    buttonClassName ||
                        'inline-flex justify-center py-2 px-3 text-lg font-semibold mt-6 border outline-2 outline-offset-2 hover:shadow-xs hover:opacity-80 cursor-pointer gap-2',
                    featured
                        ? 'text-event-accent bg-white border-event-accent'
                        : 'text-white bg-event-accent border-white',
                )}
                aria-label={title}
            >
                <span>{title}</span>
                {price && <span className="text-white opacity-70 font-normal">{price}</span>}
            </a>

            {target === '' && (
                <IframePopup title={title} url={url} isOpen={isOpen} onClose={() => setIsOpen(false)} width="100%" />
            )}
        </div>
    );
};

export default BuyTicketsButton;
