import * as React from 'react';
import { useState } from 'react';

import { cn } from '@blockworks/ui/utils';

import IframePopup from '@/components/display/popup/iframe-popup';

type MainMenuButtonProps = {
    className?: string;
    primary?: boolean;
    url: string;
    target: string | '' | '_blank';
    title: string;
    onClick?: () => void;
};

const MainMenuButton = (props: MainMenuButtonProps) => {
    const { className, url, target, title, primary, onClick } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={cn('relative', className)}>
            <div className="flex-grow text-xl text-center capitalize text-white font-normal">
                <a
                    className={cn(
                        'px-5 py-3 border cursor-pointer inline-block',
                        primary
                            ? `hover:bg-white hover:text-event-accent hover:border-event-accent text-white bg-event-accent border-white`
                            : `hover:bg-event-accent hover:border-white hover:text-white text-event-accent bg-white border-event-accent`,
                    )}
                    onClick={() => {
                        onClick && onClick();
                        if (target === '') {
                            setIsOpen(true);
                        }
                    }}
                    href={target === '' ? undefined : url}
                    target={target}
                >
                    {title}
                </a>

                {target === '' && (
                    <IframePopup
                        title={title}
                        url={url}
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        width="100%"
                    />
                )}
            </div>
        </div>
    );
};

export default MainMenuButton;
