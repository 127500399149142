import * as React from 'react';
import { useRouter } from 'next/router';

import { BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';

import NewsletterSubscribeInput from '@/components/pages/frontpage/components/newsletter-subscribe-input';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

type EventLivestreamNewsletterGateProps = {
    title: string;
    onSubscribed?: (userData: { email: string }) => void;
};

const EventLivestreamNewsletterGate = (props: EventLivestreamNewsletterGateProps) => {
    const router = useRouter();
    const { title, onSubscribed } = props;

    return (
        <div className="flex flex-col justify-start gap-5 w-70">
            <p className="text-sm mt-12">
                Please enter your email address to gain access to the {title} livestream. <br />
                This email will be subscribed to our Daily Newsletter and the Blockworks Research Newsletter:
            </p>
            <NewsletterSubscribeInput
                className="w-full lg:w-[50%]"
                inputPlaceholder="Enter email address"
                buttonText="Subscribe"
                source="newsletter-page"
                onButtonClick={({ email }) => {
                    return subscribeToNewsletter({
                        email,
                        subscribeTo: [
                            BeehiivPublicationIds.Daily,
                            BeehiivPublicationIds.Weekly,
                            BeehiivPublicationIds.Events,
                            BeehiivPublicationIds.Webinars,
                        ],
                        source: 'event-livestream',
                        sourcePath: router.asPath,
                    }).then(() => {
                        onSubscribed && onSubscribed({ email: email });
                    });
                }}
            />
        </div>
    );
};

export default EventLivestreamNewsletterGate;
